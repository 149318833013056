@import "./variables.module.scss";

.close-icon {
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    font-size: 12px;
    line-height: 12px;
    padding: 10px 30px;
    background: #454545;
    color: #ffffff;
    cursor: pointer;
    z-index: 9;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -1px 5px 1px #121212;
    transition: 0.5s;

    &:hover {
        padding: 10px 50px;
        transition: 0.5s;
    }
}

.in-course .close-icon {
    display: block;
}

@media screen and (orientation:portrait) {
    .close-icon {
        position: fixed;
        bottom: 77.5px;
        padding: 10px 7.5px 10px 15px;
        z-index: 999999;
        text-align: center;
    }

    .deck-nav.close-icon {
        bottom: 82.5px;
    }

    .in-game.close-icon {
        bottom: 80px;
    }
}

.gofull-btn {
    top: 20px;
    right: 20px;
    position: absolute;
    z-index: 99999999999999999999999999;

    i {
        margin: 0 !important;
    }
}

// ===================================
// Game Styles
// ===================================

@media screen and (orientation:landscape) {
    #c2canvasdiv {
        margin-top: 0 !important;
    }
}



// ===================================
// Document Styles
// ===================================

.doc-viewport {
    overflow: scroll scroll;
    height: 100%;
    width: calc(100% + 10px);
    position: relative;

    .linkAnnotation {
        position: absolute;
    }

    .linkAnnotation a {
        display: flex;
        width: 100%;
        height: 100%;
    }

}

.rotated.fullscreen-target {
    transform: rotate(90deg) !important;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
}

.react-pdf__Page__textContent {
    user-select: none !important;
}

// ===================================
// Video Styles
// ===================================

.video-responsive {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative;
}

.video-responsive-portrait {
    width: 100%;
    height: 0;
    padding-bottom: 177.78%;
    overflow: hidden;
    position: relative;
}

.video-responsive .react-player {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

// ===================================
// HTML Reader Styles
// ===================================

.html-reader {
    img {
        max-width: 100%;
        height: auto;
    }

    figure.table {
        margin: 0 auto
    }

    figure.media {
        margin: 0 auto
    }

    table {
        border-collapse: collapse;
        max-width: 100%;
        overflow-x: auto;
    }

    th,
    td {
        padding: 3px 6px;
        border: 1px solid #cccccc;
    }
}

// ===================================
// Meme Styles
// ===================================
.meme-reader {
    .meme-container {
        position: relative;
    }

    .meme-text-top {
        position: absolute;
        top: 14px;
    }

    .meme-text-bottom {
        position: absolute;
        bottom: 0;
    }
}

// ===================================
// QDF Styles
// ===================================
.background-image-template {
    .content-position {
        width: auto;
        height: auto;
        position: absolute;
        opacity: 0.85;

        div {
            box-shadow: 1px 1px 5px 1px #000000;
        }
    }

    .content-position.top-left {
        top: 45px;
        left: 0;
        border-radius: 0 10px 10px 0;
        text-align: left;
    }

    .content-position.top-center {
        top: 45px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 10px;
        text-align: center;
    }

    .content-position.top-right {
        top: 45px;
        right: 0;
        border-radius: 10px 0 0 10px;
        text-align: right;
    }

    .content-position.center-left {
        top: 45%;
        left: 0;
        transform: translate(0, -50%);
        border-radius: 0 10px 10px 0;
        text-align: left;
    }

    .content-position.center-center {
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        text-align: center;
    }

    .content-position.center-right {
        top: 45%;
        right: 0;
        transform: translate(0, -50%);
        border-radius: 10px 0 0 10px;
        text-align: right;
    }

    .content-position.bottom-left {
        bottom: 45px;
        left: 0;
        border-radius: 0 10px 10px 0;
        text-align: left;
    }

    .content-position.bottom-center {
        bottom: 45px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 10px;
        text-align: center;
    }

    .content-position.bottom-right {
        bottom: 45px;
        right: 0;
        border-radius: 10px 0 0 10px;
        text-align: right;
    }
}

.video-full-paragraph {
    .overlay-style {
        top: 0px;
        left: 0px;
        opacity: 0.65;
    }

    .header-style {
        top: 50%;
        left: 0px;
        transform: translate(0px, -50%);
        opacity: 0.85;
    }
}

$arrow-size: 8px;
$avatar-size: 36px;
$avatar-margin: 8px;

.conversation {
    display: flex;
    flex-direction: column;

    .message {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        max-width: 80%;
        padding: 10px;
        border-radius: 8px;
        margin: 8px;
        position: relative;

        &.mine {
            align-self: flex-end;
            background-color: #dddddd;
            color: #000000;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: -$arrow-size;
                border-style: solid;
                border-width: $arrow-size $arrow-size 0 0;
                border-color: #dddddd transparent transparent transparent;
            }
        }

        &.theirs {
            background-color: #454545;
            color: #ffffff;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: -$arrow-size;
                border-style: solid;
                border-width: $arrow-size 0 0 $arrow-size;
                border-color: #454545 transparent transparent transparent;
            }
        }

        .conversation-avatar {
            width: $avatar-size;
            height: $avatar-size;
            margin-right: $avatar-margin;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }

            &.mine {
                float: right
            }
        }

        .message-content {
            margin-bottom: 4px;
        }

    }
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    /* Adjust the gap between images as needed */
    .grid-item {
        width: 100%;
        height: auto;
    }
}



.multiselect {

    .ui.checkbox label,
    .ui.checkbox+label {
        font-size: 1.25em;
        line-height: 1.1;
    }
}

// ===================================
// Product Card Styles
// ===================================
.product-card-reader {
    .product-card-name {
        position: absolute;
        padding: 10px 20px;
        z-index: 2;
        width: 80%;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
    }

    .button-container {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
    }

    .product-card-content {
        width: calc(100% - 50px);
        align-content: flex-start !important;
        justify-content: flex-start;

        .product-card-inner {
            padding: 0 20px 15px 55px;
        }

    }
}

.product-grid-reader {
    .product-grid-name {
        position: absolute;
        padding: 10px 20px;
        z-index: 2;
        width: 80%;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
    }

    .button-container {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 15px;
        width: calc(100% - 30px);
        transform: translate(0, -50%);

        .product-grid-btn {
            width: calc(33.33% - 15px);

            .product-grid-btn-img {
                width: 100%;
                height: 0;
                padding-bottom: 100%;

                img {
                    width: 100%;
                }

                &:hover {
                    width: 100%;
                    padding-bottom: calc(100% - 10px);
                    border: 5px solid #ffbf00;
                    border-radius: 50%;
                }
            }

        }
    }

    .product-grid-content {
        background: rgba(0, 0, 0, 0.75);
        align-items: flex-start !important;
        justify-content: flex-start;

        .content-block {
            width: calc(100% - 60px);
        }

        .support-btn:hover {
            background: #000000;
        }
    }
}

// ===================================
// Product FAQ Styles
// ===================================

.product-faq-reader {
    .product-name {
        position: absolute;
        padding: 10px 20px;
        z-index: 2;
        width: 80%;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
    }
}

// ===================================
// Product Catalog Styles
// ===================================

.product-catalog-reader {
    .product-name {
        position: absolute;
        padding: 10px 20px;
        z-index: 2;
        width: 80%;
        left: 50%;
        bottom: 0;
        text-align: center;
        transform: translate(-50%, 0);
    }
}

// ===================================
// Product Catalog Styles
// ===================================

.flash-card,
.challenge-card {
    width: 100%;
    height: 100%;
    position: relative;


    /* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
    .flip-card {
        position: absolute;
        background-color: transparent;
        width: 100%;
        height: 100%;
        border: 1px solid #f1f1f1;
        perspective: 1000px;
        /* Remove this if you don't want the 3D effect */
    }

    /* This container is needed to position the front and back side */
    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    .flip-card-question {
        background: rgba(0, 0, 0, 0.75);
        color: #ffffff;
    }

    /* Do an horizontal flip when you move the mouse over the flip box container */
    .flip-card.flipped .flip-card-inner {
        transform: rotateY(180deg);
    }

    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        /* Safari */
        backface-visibility: hidden;
    }

    /* Style the front side (fallback if image is missing) */
    .flip-card-front {
        background-color: #bbb;
        color: black;
    }

    /* Style the back side */
    .flip-card-back {
        background-color: dodgerblue;
        color: white;
        transform: rotateY(180deg);
    }

    .flipper {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 40%);
        z-index: 2;
        padding: 10px;
        transition: all 0.2s linear;

        i {
            margin: 0 !important;
            padding: 0 !important;
        }

        &:hover {
            padding: 14px;
        }
    }

}

.flash-card {
    height: 0;
    padding-bottom: 133%;
}

.assignment-reader {
    .remarks-overlay {
        top: 0;
        left: 0;

        .pattern-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #e5e5f7;
            opacity: 0.4;
            background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #e5e5f7 10px), repeating-linear-gradient(#444cf755, #454545);
        }

        .remarks-header {
            position: absolute;
            top: -45px;
            left: 0;
            padding: 12px 24px;
            border-top-right-radius: 10px;
        }
    }
}

.html-feedback {
    img {
        width: 100%;
        height: auto;
    }
}

.qoption:hover {
    box-shadow: 1px 1px 2px 1px #cccccc;
}