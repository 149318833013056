.course-progress-radial {
    position: absolute;
    right: 10px;
    top: 10px;
}

#viewport {
    width: 100%;
    height: calc(100% - 60px);
    position: relative;
}

.isolated #viewport {
    height: 100%;
}

.scroller {
    position: absolute;
    top: 0;
    left: 0;
    overflow: visible;
    width: 100%;
    height: 100%;
    // padding-bottom: 66.67%;
}

@media (orientation: landscape) {
    // .course-progress-radial {
    //     right: 30px;
    // }

    #viewport {
        width: 100%;
        height: 0;
        padding-bottom: 66.67%;
        position: relative;
    }

    .scroller {
        height: 0;
        padding-bottom: 66.67%;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroller::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroller {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.wrapper-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    pointer-events: none;
}

.overlay.loc-menu {
    pointer-events: auto;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    transition: 0.5s;
}

@media screen and (orientation:portrait) {
    .overlay.loc-menu {
        border-radius: 0;
    }

    .scroller {
        overflow: scroll;
        width: calc(100% + 30px);
        height: calc(100% + 30px);
        padding-bottom: 0;
    }

}

// Marker Styles
// ------------------------------------

.marker-icon {
    position: absolute;
    font-size: 1em;
    width: 2.5em;
    height: auto;
    top: 50%;
    left: 50%;
    z-index: 999;
    background: #000000;
    color: white;
}


@media (orientation: portrait) {
    .marker-icon {
        font-size: 1.2em;
    }

    .marker-icon.complete .beacon::after {
        content: "";
        position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        /* Adjust color and opacity as desired */
        border: 0.5em solid rgba(255, 255, 255, 1);
        /* Adjust color and opacity as desired */
        animation: rings 2s ease-in-out infinite;
    }
}

.beacon {
    position: relative;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.45);
    box-shadow: 1px 1px 1.5em 1px #ffffff;
}

.marker-icon.current .beacon::after {
    content: "";
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* Adjust color and opacity as desired */
    border: 0.5em solid rgba(255, 255, 255, 1);
    /* Adjust color and opacity as desired */
    animation: rings 2s ease-in-out infinite;
}

@keyframes rings {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(3);
        opacity: 0;
    }
}

.marker-text {
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    top: -1.5em;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 9999;
    text-align: center;
    font-weight: bolder;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 1.5em 1.5em 100% 100%;
    padding-top: 0.6em;
    box-shadow: 0 0 1em 1px #ffffff;
    color: #ffffff;
}

.marker-name {
    position: absolute;
    top: -4em;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 9999;
    text-align: center;
    font-weight: bolder;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 0.6em;
    border-radius: 10px;
    box-shadow: 0 0 1em 1px #ffffff;
    color: #000000;
}

.marker-icon.incomplete {
    .marker-text {
        background-color: rgba(90, 0, 0, 0.65);
        border-radius: 50%;
    }
}

.marker-icon.complete {
    .marker-text {
        background-color: rgba(0, 60, 0, 0.65);
        border-radius: 50%;
    }
}

.marker-icon.current {
    opacity: 1;

    .beacon {
        background-color: rgba(80, 0, 0, 1);
    }
}

.marker-icon:not(.incomplete) .marker-text::before {
    content: "";
    position: absolute;
    top: 2.4em;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
    border-top: 0.5em solid black;
}

.marker-icon.complete .marker-text::before {
    border-top: 0.5em solid rgba(0, 60, 0, 0.65);
}

.marker-icon.marker-icon.incomplete:not(.sequential) .marker-text::before {
    content: "";
    position: absolute;
    top: 2.4em;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
    border-top: 0.5em solid rgba(90, 0, 0, 0.65);
}

.marker-icon.incomplete.sequential {
    opacity: 0.75;
    filter: grayscale(100);

    .marker-text {
        background-color: rgba(200, 200, 200, 0.65);
        border-radius: 50%;
    }
}