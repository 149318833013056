//---------------------------------------------------------
//Search Result Page Style
//---------------------------------------------------------

.view-toggle-button {
  justify-content: space-evenly !important;
}

.css-1abq8k5 {
  display: none !important;
}
