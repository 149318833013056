$width: 900px;
$primary-color: #cccccc;
$primary-text-color: #000000;
$secondary-color: #666666;
$secondary-text-color: #ffffff;
$background-default: #f5f5f5;
$box-shadow-default: 1px 1px 100px 1px #ccc;
$banner-gradient: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);

.analytics-page {
    width: $width;
    margin-left: -80px !important;
    padding: 0;

    .close-btn {
        position: absolute;
        right: -100px;
        top: 20px
    }
}

.report-page {
    position: relative;
    width: $width;
    margin: 20px 25px 30px 50px;
    height: 1270px;
    background: $background-default;
    font-size: 0.9em;
    box-shadow: $box-shadow-default;

    .tabber {
        position: absolute;
        left: 0;
        top: 10px;
        transform-origin: 0 0;
        transform: rotate(90deg);
    }

    .bannerSection {
        width: 100%;
        height: 0;
        padding-bottom: 12.5%;
        position: relative;

        img {
            width: 100%;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            padding-bottom: 12.5%;
            background: $banner-gradient;
            opacity: 0.5;
        }

        .profileImage {
            position: absolute;
            left: 25px;
            bottom: -75px;
            width: 160px;
            height: 160px;
        }
    }

    .ui.progress:last-child {
        margin: 0 !important;
    }

    .collectible-tag {
        transform-origin: 0 0;
        transform: rotate(90deg);
    }

    .small .certificate {
        font-size: 0.2em;
    }
}


%basic-tab {
    margin-right: 5px;
    padding: 6px 10px;
    border-radius: 10px 10px 0 0;
    transform: rotate(180deg);
    font-size: 1.25em;
    display: inline-block;
    cursor: pointer;
}

.tabber {

    .tab {
        @extend %basic-tab;
        background: $primary-color;
        color: $primary-text-color;
    }

    .activeTab {
        @extend %basic-tab;
    }

    .teamTab {
        @extend %basic-tab;
        background: #999999;
        color: #ffffff;
    }

    .activityTab {
        @extend %basic-tab;
        background: $secondary-color;
        color: $secondary-text-color;
    }
}

.article-summary .ui.three.statistics .statistic {
    padding: 15px;
    background-color: $primary-color !important;
    border: 5px solid white;

    * {
        color: $primary-text-color !important;
    }
}