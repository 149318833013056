.ribbon-menu {
    position: relative;
    background: #cccccc;

    .breadcrumbs {
        position: absolute;
        right: 0;
        height: 43px;
        line-height: 43px !important;

        .deck-format {
            padding: 0 30px;
            line-height: 43px !important;
        }

    }

    .ui.attached.tabular.menu {
        margin-left: 25px;
    }

    .ui.tabular.menu .active.item {
        background: #e5e5e5;
    }

    .ui.segment[class*="bottom attached"] {
        background: #e5e5e5;
        box-shadow: 0 1px 2px 1px #cccccc;
    }

    .ribbon-btn {
        padding: 0 15px;

        .sub-btn {
            min-width: 80px;

            &:hover {
                box-shadow: 0 1px 10px 1px #666666 !important;
            }
        }

        .sub-btn.active {
            background: #dddddd;
            pointer-events: none;
        }

        .sub-text-btn {
            min-width: 80px;

            &:hover {
                font-weight: bold !important;
            }
        }
    }
}

.ck.ck-editor__editable_inline {
    padding: 5px 10px !important;
}

.ck.ck-block-toolbar-button {
    margin-left: 10px !important;
}

.select-icon {
    background: #ffffff;
    color: #333333;
    padding: 10px 5px 5px 5px;

    &:hover {
        background: #454545;
        color: #ffffff;
    }
}

.selected-icon {
    padding: 7.5px 2.5px 2.5px 5px;
}

.sub-block-delete {
    right: -4px;
    border-bottom-left-radius: 10px;

    &:hover {
        background: darkred !important;
        box-shadow: 1px 1px 5px 2px #000000 !important;
    }
}

.react-datepicker-wrapper input {
    border: none;
    text-align: center;
    padding: 11px;
    color: #000000;
    width: 100%
}

.dp-stacked .react-datepicker-wrapper input {
    text-align: left;
}

.ui.styled.accordion .title {
    font-size: 1.25em;
    color: #000000;
    background-color: #efefef;
}

.ui.styled.accordion .active.title {
    background-color: #d5d5d5;
}

.content-tree-header {
    background: #666666 !important;
    color: #ffffff !important;
    pointer-events: none;
    text-transform: uppercase !important;
    font-weight: bold !important;
    cursor: none;
}

.content-tree-footer {
    background: #f5f5f5 !important;
    color: #ffffff !important;
}

.content-area {
    .four.wide.column {
        width: 33% !important;
    }

    .stretched.twelve.wide.column {
        width: 66% !important;
        padding-left: 0 !important;
    }

    .ui.dropdown .item .left.dropdown.icon,
    .ui.dropdown .left.menu .item .dropdown.icon {
        margin-top: -3px !important;
        margin-right: 20px !important;
        font-size: 1.5em;
    }
}

.link-input-field {
    width: 140px;
    white-space: nowrap;
    position: relative;
    z-index: 999;

    .divider.text {
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}