@import "./variables.module.scss";

.qd-nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    height: $navbar-height;

    .nav-inner {
        width: 100%;
        max-width: $max-width;
        margin: 0 auto;

        .logo-block {
            display: inline-block;
            cursor: pointer;

            .logo {
                height: $navbar-height;
            }
        }

        .pagename {
            padding-left: 15px;
            font-size: 1.75em;
            line-height: $navbar-height !important;
            display: inline-block;
            vertical-align: middle;
        }

        .notification-icon {
            font-size: 1.5em;
            line-height: $navbar-height;
            vertical-align: middle;
        }

        .ui.scrolling.dropdown .menu {
            max-height: calc(100vh - 125px) !important;
            box-shadow: 0 0 50px;
        }

        .ui.dropdown .menu>* {
            white-space: normal !important;
        }

        .notification-icon .dropdown.icon {
            display: none !important;
        }

        .username {
            padding-top: 3px;
            padding-right: 8px;
            font-size: 1.2em;
            line-height: $navbar-height;
            vertical-align: middle;
        }

        .ellipsis {
            cursor: pointer;
            vertical-align: middle;
        }
    }
}

@media (orientation: portrait) {
    .qd-nav {
        .nav-inner {
            .pagename {
                padding-top: 3px;
                font-size: 1.5em;
            }

            .logo-block {
                height: $navbar-height;
                vertical-align: middle;
                padding: 5px;

                .logo {
                    height: $mobile-logo-height;
                }
            }
        }
    }
}

@media (orientation: landscape) {
    .qd-nav {
        height: $navbar-desktop-height;

        .nav-inner {
            padding-top: 15px !important;

            .logo-block {
                margin: 0 5px 15px 5px;
                padding: 10px;

                .logo {
                    height: $navbar-desktop-height;
                }
            }
        }
    }

    .qd-nav:not(.builder) {
        .nav-inner {
            background: #efefef;
        }
    }

    body.builder .qd-nav .nav-inner {
        max-width: $max-creator-width !important;
    }
}

@media (orientation: landscape) and (max-height: 768px) {
    .qd-nav .nav-inner {
        max-width: $max-creator-width !important;
    }
}

@media screen and (orientation: landscape) and (max-width: 1112px) {
    .qd-nav {
        height: $navbar-height;

        .nav-inner {
            background: #efefef;
            padding-top: 0 !important;

            .logo-block {
                margin: 0 5px 15px 5px;
                padding: 10px;
                box-shadow: 1px 1px 2px 1px #cccccc;

                .logo {
                    height: $navbar-height;
                }
            }
        }
    }
}


.qd-nav.builder {
    width: calc(100% - 60px) !important;
    left: 60px;
    height: 60px;

    .nav-inner {
        padding: 0 !important;
    }
}

@media screen and (orientation: landscape) and (min-width: $creator-nav) {
    .qd-nav.builder {
        width: $creator-nav !important;
        left: calc(50% + 30px);
        transform: translateX(-50%);
    }
}

.qd-left-menu {
    height: calc(100% - #{$navbar-desktop-height});
    border-top-right-radius: 50px;
    position: relative;

    .core-menu {
        padding-top: 45px;
        border-left: 1px solid #ffffff;
        margin-left: 30px;

        .core-link {
            display: block;
            color: #ffffff;
            padding: 7.5px 10px;
            clear: both;

            .icon {
                font-size: 1.5em;
                vertical-align: middle;
                margin-right: 10px;
            }

            &:hover {
                font-weight: bold;
            }
        }
    }
}

.quick-menu {
    width: 100%;

    .quick-link {
        display: inline-block;
        color: #ffffff;
        margin-right: 7.5px;
        margin-bottom: 7.5px;
        border-radius: 10px;

        img {
            width: 100%;
            border-radius: 10px;

            &:hover {
                box-shadow: 1px 1px 3px 1px #000000;
                margin-top: -3px;
                margin-bottom: 3px;
            }
        }


    }
}

.quick-menu:not(.vertical) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 1px;
}

.quick-menu.vertical {
    position: absolute;
    bottom: 20px;
    left: 20px;

    .quick-link {
        display: block;
        clear: both;
        width: calc(100% - 10px);

        img {
            width: calc(100% - 30px) !important;
        }
    }
}

.qd-bottom-menu.ui.menu {
    height: 100%;
    padding: 5px 10px !important;
    border-radius: 10px 10px 0 0;

    .item {
        height: 80px;
        font-size: 0.8em;
        background: transparent;
        min-width: 0 !important;
        flex-direction: initial;
        justify-content: space-between;
        box-shadow: 1px 0 10px 0.5px #00000022;

        a,
        span {
            margin-top: 5px;
            color: #ffffff;
        }

        .more.mobile-link .icon {
            margin-top: 7px;
        }

        .ui.upward.dropdown .menu {
            margin-bottom: 15px;
            margin-right: -15px;
            background: #454545 !important;
            padding: 15px 2.5px 2.5px 2.5px;
            border-radius: 10px 10px 0 0;
        }

        .ui.upward.dropdown .item {
            height: auto;
            font-size: 1.2em !important;
            color: #ffffff !important;
            margin: 0;
            padding: 5px 7.5px !important;
        }

        .ui.upward.dropdown>.dropdown.icon {
            display: none;
        }

        .quick-link {
            display: block;
            color: #ffffff;
            margin: 0;
            clear: both;
            border-radius: 10px;

            img {
                width: 100%
            }
        }
    }

    .icon {
        font-size: 2.25em;
        margin-bottom: 5px;
    }
}

.location-menu {
    height: 100%;
    width: 0;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.25s;
    border-radius: 20px 0 0 20px;

    .location-menu-inner {
        margin-left: 24px;
        width: calc(100% - 24px);
        height: 100%;

        .location-menu-item {
            padding: 10px !important;
        }

        .location-menu-item.active {
            cursor: pointer;
        }

        .location-menu-item.inactive {
            background: #efefef;
            color: #bdbdbd;
            pointer-events: none;
        }
    }
}

@media screen and (orientation: portrait) {
    .notification-icon {
        margin-right: 5px !important;
    }

    .username {
        display: none;
    }
}