// Page Definitions
//-----------------------------
$navbar-height: 55px;
$mobile-logo-height:45px;
$navbar-desktop-height: 80px;

$max-width: 1660px;
$max-creator-width: 1280px;
$creator-nav: 1220px;

$max-height: 2000px;
$header-font: "Oswald";
$mobile-buffer: 60px;
$sidebar-width: 300px;

:export {
    navbarHt: $navbar-height;
    sidebarWd: $sidebar-width;
    maxWd: $max-width;
    maxHt: $max-height;
}

body {
    max-width: $max-width !important;
    margin: 0 auto;
}

body.builder {
    max-width: $max-creator-width !important;
}

@media (orientation: landscape) and (max-height: 768px) {
    body {
        max-width: $max-creator-width !important;
    }
}